import React from "react"
import styled from "styled-components"
import { fonts } from "../../utils/fonts"
import { color } from "../../utils/colors"
import { LayoutWrapper } from "../../components/LayoutWrapper/LayoutWrapper"
import CarouselSlide from "../../components/CarouselSlide/CarouselSlide"

// Import Swiper styles
import "swiper/swiper.scss"

const StyledWrapper = styled.section`
  padding-top: 80px;
  padding-bottom: 80px;
  .swiper-container {
    border-left: solid 1px ${color.separatorGrey};
  }
`

const StyledHeading = styled.h3`
  ${fonts.headingMedium};
  text-align: center;
`

const StyledSubheading = styled.p`
  ${fonts.paragraph};
  margin-top: 20px;
  text-align: center;
  margin-bottom: 80px;
`

const StyledCardsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  > * {
    width: 33.333%;
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
    > * {
    width: 100%;
  }
  }
`

const Carousel = ({ slides, heading, subheading }) => {
  return (
    <StyledWrapper>
      <LayoutWrapper>
        <StyledHeading>{heading}</StyledHeading>
        <StyledSubheading>{subheading}</StyledSubheading>
        <StyledCardsWrapper>
          {slides.map(slide => {
            return <CarouselSlide {...slide} />
          })}
        </StyledCardsWrapper>
        {/*<Swiper*/}
        {/*  slidesPerView={1}*/}
        {/*  loop={true}*/}
        {/*  breakpoints={{*/}
        {/*    // when window width is >= 640px*/}
        {/*    1100: {*/}
        {/*      slidesPerView: 3,*/}
        {/*    },*/}
        {/*    768: {*/}
        {/*      slidesPerView: 2,*/}
        {/*    },*/}
        {/*  }}*/}
        {/*>*/}
        {/*  {slides.map(slide => {*/}
        {/*    return (*/}
        {/*      <SwiperSlide>*/}
        {/*        <CarouselSlide {...slide} />*/}
        {/*      </SwiperSlide>*/}
        {/*    )*/}
        {/*  })}*/}
        {/*</Swiper>*/}
      </LayoutWrapper>
    </StyledWrapper>
  )
}

export default Carousel

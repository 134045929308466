import styled from "styled-components"
import React from "react"
import PropTypes from "prop-types"
import Button from "../Button/Button"
import { fonts } from "../../utils/fonts"
import { LayoutWrapper } from "../LayoutWrapper/LayoutWrapper"

const StyledHero = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden;
  position: relative;
  //color: white !important;
  @media screen and (max-width: 1200px) {
    height: calc(100vh - 80px);
  }
  @media screen and (max-width: 767px) {
    height: calc(100vh - 60px);
  }
`

const StyledHeroCopy = styled.h2`
  ${fonts.headingExtraLarge};
  padding-top: 60px;
  width: 75%;
  @media screen and (max-width: 1300px) {
    width: 66.65%;
  }
  @media screen and (max-width: 1200px) {
    font-size: 40px;
  }
  @media screen and (max-width: 767px) {
    margin-top: 40px;
    margin-bottom: 30px;
    max-width: 100%;
  }
`

const StyledSubtitle = styled.p`
  ${fonts.headingExtraSmall};
  margin-top: 20px;
`

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 50px;

  @media screen and (max-width: 767px) {
    margin-top: 20px;
  }
`

const StyledBackground = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  z-index: -1;
`

const Hero = ({ text, subtitle, background, buttonText, buttonLink }) => (
  <StyledHero>
    <StyledBackground src={background} alt="" />
    <LayoutWrapper>
      <StyledHeroCopy>{text}</StyledHeroCopy>
      <StyledSubtitle>{subtitle}</StyledSubtitle>
      <ButtonsWrapper>
        <Button to={buttonLink}>{buttonText}</Button>
      </ButtonsWrapper>
    </LayoutWrapper>
  </StyledHero>
)

Hero.propTypes = {
  text: PropTypes.string,
  subtitle: PropTypes.string,
  background: PropTypes.object.isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonLink: PropTypes.string.isRequired,
}

export default Hero

import React from "react"
import styled from "styled-components"
import { fonts } from "../../utils/fonts"
import { LayoutWrapper } from "../../components/LayoutWrapper/LayoutWrapper"
import { color } from "../../utils/colors"
import FormattedArticle from "../../components/FormattedArticle/FormattedArticle"

const StyledWrapper = styled(LayoutWrapper)``

const StyledTitleLarge = styled.h2`
  ${fonts.headingLarge};
  width: calc(100% / ${({ equalSections }) => (equalSections ? 2 : 3)});
  flex-shrink: 0;
  padding-right: 40px;
  border-right: solid 1px ${color.separatorGrey};
  color: ${({ color }) => color};
  padding-bottom: 150px;
  @media screen and (max-width: 1100px) {
    padding-bottom: 80px;
  }
  @media screen and (max-width: 1000px) {
    border-right: none;
    padding-bottom: 20px;
    width: 100%;
  }
  @media screen and (max-width: 767px) {
    padding-right: 0;
  }
`

const StyledColumnWrapper = styled.div`
  padding-left: 40px;
  display: flex;
  flex-direction: column;
  padding-bottom: 150px;
  flex-grow: 1;
  @media screen and (max-width: 1100px) {
    padding-bottom: 80px;
  }
  @media screen and (max-width: 1000px) {
    padding-left: 0;
    padding-bottom: 0;
  }
`

const StyledRowWrapper = styled.div`
  display: flex;
  border-top: solid 1px
    ${({ noSeparator }) => (noSeparator ? "transparent" : color.separatorGrey)};
  border-bottom: solid 1px
    ${({ noSeparator, lastChild }) => (noSeparator || lastChild ? "transparent" : color.separatorGrey)};
  padding-top: 100px;
  padding-bottom: ${({ lastChild }) => !lastChild && "80px"};
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    padding-top: 50px;
    padding-bottom: 40px;
  }
`

const StyledFaqItem = styled.details`
  width: 100%;
  &[open] {
    summary::after {
      content: "-";
    }
  }
`

const StyledFaqAnswer = styled.div`
  padding-bottom: 10px;
`

const StyledList = styled.ol`
  counter-reset: section;
  list-style: circled-alpha;
`

const StyledQuestion = styled.summary`
  ${fonts.headingExtraSmall};
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
  display: flex;
  width: 100%;
  outline: none;
  padding: 22px 30px 22px 0;
  position: relative;
  box-sizing: border-box;
  span {
    flex-shrink: 1;
    flex-grow: 0;
    ::before {
      counter-increment: section;
      content: counter(section) ". ";
      margin-right: 4px;
      display: inline;
    }
  }
  &::after {
    content: "+";
    font-size: 18px;
    font-weight: 700;
    margin-left: auto;
    width: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 0.8em;
    flex-shrink: 0;
    position: absolute;
    right: 0;
    top: 22px; 
  }
  
  ::-webkit-details-marker {
    display: none;
  }
`

const Faq = ({ faqItems, noSeparator, lastChild }) => (
  <StyledWrapper>
    <StyledRowWrapper noSeparator={noSeparator} lastChild={lastChild}>
      <StyledTitleLarge>Pytania i odpowiedzi</StyledTitleLarge>
      <StyledColumnWrapper>
        <StyledList>
          {faqItems.map(({ question, answer }) => {
            return (
              <li>
                <StyledFaqItem>
                  <StyledQuestion><span>{question}</span></StyledQuestion>
                  <StyledFaqAnswer>
                    <FormattedArticle article={answer} />
                  </StyledFaqAnswer>
                </StyledFaqItem>
              </li>
            )
          })}
        </StyledList>
      </StyledColumnWrapper>
    </StyledRowWrapper>
  </StyledWrapper>
)

export default Faq

import React from "react"

import Layout from "./../layouts/Layout/Layout"
import Hero from "../components/Hero/Hero"
import HeroQuote from "../components/HeroQuote/HeroQuote"
import FeaturesList from "../sections/FeaturesList/FeaturesList"
import ThreeColSection from "../sections/ThreeColSection/ThreeColSection"
import TwoColumnSection from "../sections/TwoColumnSection/TwoColumnSection"
import Carousel from "../sections/Carousel/Carousel"

import HeroBackground from "../assets/images/hero_bg.jpg"
import HeroShortBackground from "../assets/images/hero_mainShort.jpg"
import IlluStudent1 from "../assets/images/illu_student1.jpg"
import IlluStudent2 from "../assets/images/illu_student2.jpg"
import IlluStudent3 from "../assets/images/illu_student3.jpg"
// import IlluStudent4 from "../assets/images/illu_student4.jpg"
// import IlluStudent5 from "../assets/images/illu_student5.jpg"

import IconEducation from "../assets/svg/icon_education.svg"
import IconMentoring from "../assets/svg/icon_mentoring.svg"
import IconApprenticeship from "../assets/svg/icon_apprenticeship.svg"
import IconScholarship from "../assets/svg/icon_scholarship.svg"
import IconSkills from "../assets/svg/icon_skills.svg"
import IconMentorCare from "../assets/svg/icon_mentorCare.svg"
import IconWorkshops from "../assets/svg/icon_workshops.svg"
import IconChart from "../assets/svg/icon_chart.svg"
import IconExperts from "../assets/svg/icon_experts.svg"
import IconSciScholarship from "../assets/svg/icon_scienceScholarship.svg"
import IconSciWallet from "../assets/svg/icon_wallet.svg"
import IconID from "../assets/svg/icon_identifiacationCard.svg"
import IconOne from "../assets/svg/icon_one.svg"
import IconTwo from "../assets/svg/icon_two.svg"
import IconThree from "../assets/svg/icon_three.svg"
import Faq from "../sections/Faq/Faq"

const IndexPage = () => (
  <Layout isHeaderTransparent>
    <Hero
      text="Akademia Alkantara dla zdolnych studentów"
      subtitle="Edukacja // Mentoring // Stypendium // Praktyka"
      background={HeroBackground}
      buttonText="Sprawdź"
      buttonLink={"/akademia"}
    />
    <TwoColumnSection
      heading="O nas"
      article={
        "<h3>Wspieramy na drodze do wymarzonej kariery</h3>" +
        "<p>Program Alkantara prowadzony przez Fundację Inspirujące Przykłady to zajęcia biznesowe oraz fundusz stypendialny stworzony dla zdolnych studentów z województwa pomorskiego i nie tylko. Naszym celem jest rozwój kompetencji biznesowych młodych ludzi i zapewnienie im lepszego startu na rynku pracy. Tworzymy wartościową przestrzeń do mocnego wejścia na rynek pracy.</p>"
      }
    />
    <FeaturesList
      title="Jak działamy?"
      subtitle="Program Alkantara obejmuje 4 obszary działalności:"
      noBorder
      items={[
        {
          backgroundColor: "#9A6846",
          svg: IconEducation,
          text: "Edukacja",
          link: "/akademia#edukacja",
        },
        {
          backgroundColor: "#41431B",
          svg: IconMentoring,
          text: "Mentoring",
          link: "/akademia#mentoring",
        },
        {
          backgroundColor: "#465E9A",
          svg: IconApprenticeship,
          text: "Praktyka zawodowa",
          link: "/akademia#praktykaZawodowa",
        },
        {
          backgroundColor: "#9A46A4",
          svg: IconScholarship,
          text: "Stypendium",
          link: "/fundusz",
        },
      ]}
    />
    <HeroQuote
      heroimg={HeroShortBackground}
      heroHeading="Twórcy Programu Alkantara"
      heroText={
        "<p>Fundacja Inspirujące Przykłady</p>" +
        "<p>„Jesteśmy grupą specjalistów ściśle związanych z biznesem. Pomagamy młodym ludziom stać się profesjonalistami.”</p>"
      }
    />
    <ThreeColSection
      heading="Korzyści"
      article1="<h3>Dlaczego warto dołączyć do Programu Alkantara?</h3>"
      article2="<p>Od początku działalności kompleksowo wspieramy studentów na drodze do wymarzonej kariery. Łączymy kompetencje specjalistów, obecność mentorów z obszaru biznesu i dopasowane do potrzeb wsparcie finansowe.</p>"
    />
    <FeaturesList
      title="Dołącz do Programu Alkantara"
      items={[
        {
          backgroundColor: "#184667",
          svg: IconSkills,
          text: "Zdobywaj cenne kompetencje biznesowe",
        },
        {
          backgroundColor: "#184667",
          svg: IconMentorCare,
          text: "Korzystaj z indywidualnej opieki mentora",
        },
        {
          backgroundColor: "#184667",
          svg: IconWorkshops,
          text: "Bierz udział w profesjonalnych warsztatach",
        },
        {
          backgroundColor: "#184667",
          svg: IconChart,
          text: "Poznaj szczegółowe zasady funkcjonowania firm",
        },
        {
          backgroundColor: "#184667",
          svg: IconExperts,
          text: "Współpracuj z ekspertami ze świata biznesu",
        },
        {
          backgroundColor: "#184667",
          svg: IconSciScholarship,
          text: "Otrzymaj stypendium naukowe ",
        },
        {
          backgroundColor: "#184667",
          svg: IconSciWallet,
          text: "Podejmij płatną praktykę zawodową",
        },
        {
          backgroundColor: "#184667",
          svg: IconID,
          text: "Znajdź pracę w firmach partnerskich ",
        },
      ]}
    />
    <ThreeColSection
      heading="Program Alkantara"
      article1={
        "<h3>Akademia Alkantara</h3>" +
        "<p>To program angażujących zajęć i warsztatów biznesowych prowadzonych przez praktyków: doświadczonych przedsiębiorców, managerów zarządzających zespołami oraz świetnych szkoleniowców. Każdy z uczestników otrzymuje kompleksowe wsparcie dedykowanego mentora oraz możliwość skorzystania z płatnego stażu w wybranej partnerskiej firmie.</p>"
      }
      article2={
        "<h3>Fundusz Stypendialny Alkantara</h3>" +
        "<p>Fundusz Stypendialny w ramach Programu Alkantara stworzyliśmy z myślą o osobach, które z różnych przyczyn nie mogą podjąć wymarzonych studiów na trójmiejskich uczelniach. Wysokość stypendium ustalamy tak, by umożliwić naszym uczestnikom utrzymanie w mieście, w którym chcą studiować.</p>"
      }
      link1="/akademia"
      link2="/fundusz"
    />
    <ThreeColSection
      heading="Rekrutacja"
      article1={"<h3>Jak dołączyć do Programu Alkantara?</h3>"}
      article2={
        "<p>Program Alkantara tworzymy we współpracy ze szkołami średnimi z województwa pomorskiego. By wziąć udział w rekrutacji, skontaktuj się z wychowawcą lub doradcą zawodowym dostępnym w Twojej szkole.</p>"
      }
    />
    <FeaturesList
      colNumber={3}
      itemsSeparator
      items={[
        {
          backgroundColor: "#184667",
          svg: IconOne,
          text:
            "Zapytaj wychowawcę lub doradcę zawodowego o możliwość udziału w programie.",
        },
        {
          backgroundColor: "#184667",
          svg: IconTwo,
          text:
            "Dowiedz się, jakie dokumenty i formularze będą Ci potrzebne w procesie rekrutacji.",
        },
        {
          backgroundColor: "#184667",
          svg: IconThree,
          text:
            "Wspólnie z wychowawcą lub doradcą wypełnij potrzebne dokumenty i gotowe!",
        },
      ]}
    />
    <Carousel
      heading="Uczestnicy"
      subheading="Poznaj uczestników pierwszej edycji Programu Alkantara. Sprawdź, jakie są ich wrażenia z udziału w inicjatywie. "
      slides={[
        {
          image: IlluStudent1,
          heading: "Natalia",
          text:
            "„Od początku złapaliśmy dobry kontakt z prowadzącymi. Są kompetentni, otwarci i bardzo mili. Już wiem, że dobrze wybrałam.”",
        },
        {
          image: IlluStudent2,
          heading: "Szymon",
          text:
            "„W Akademii Alkantara warsztaty prowadzone są na bardzo wysokim poziomie, a rozmowy z mentorem dają motywującego kopa. Czekam na więcej!”",
        },
        {
          image: IlluStudent3,
          heading: "Adrian",
          text:
            "„W ramach Programu Alkantara będę mógł poznać środowisko biznesowe Trójmiasta. Pracuję z mentorem i zdobywam umiejętności, które pomogą mi znaleźć dobrą pracę.”",
        },
        // {
        //   image: IlluStudent4,
        //   heading: "Jakub",
        //   text:
        //     "„Akademię Alkantara tworzą świetni ludzie. Nie wiedziałem, że zajęcia mogą być tak wartościowe i angażujące. Warto było podjąć wyzwanie!”",
        // },
        // {
        //   image: IlluStudent5,
        //   heading: "Jan",
        //   text:
        //     "„Najmocniejszym elementem Akademii Alkantara jest współpraca z mentorem. Dostęp do wiedzy i biznesowego doświadczenia osób, które odniosły sukces jest nieoceniony.”",
        // },
      ]}
    />
    <Faq
      lastChild
      faqItems={[
        {
          question: "Czym jest Program Alkantara?",
          answer:
            "<p>Program Alkantara to zajęcia biznesowe oraz fundusz stypendialny stworzony dla zdolnych studentów z województwa pomorskiego i nie tylko. Obejmuje 4 obszary: edukację, mentoring, praktykę zawodową Jego celem jest rozwój kompetencji biznesowych młodych ludzi i zapewnienie im lepszego startu na rynku pracy.</p>",
        },
        {
          question: "Kto może wziąć udział w Akademii Alkantara?",
          answer:
            "<p>Do Programu Alkantara mogą zgłaszać się zdolni uczniowie klas maturalnych, którzy chcą studiować na trójmiejskich uczelniach. Żeby dowiedzieć się więcej, skontaktuj się ze swoim wychowawcą lub doradcą zawodowym w swojej szkole. To osoby, które pomogą Ci wypełnić wszystkie dokumenty potrzebne w procesie rekrutacji. Nabór na rok akademicki 2021/2022 trwa do 12 grudnia 2020.</p>",
        },
        {
          question: "Kto może skorzystać z Funduszu Stypendialnego?",
          answer:
            "<p>Udział w Funduszu Stypendialnym mogą zgłaszać osoby, które z różnych przyczyn nie mogą podjąć wymarzonych studiów w Trójmieście. Każde zgłoszenie rozpatrujemy indywidualnie. Wysokość stypendium ustalamy tak, by umożliwić naszym uczestnikom utrzymanie w mieście, w którym chcą studiować. Bierzemy pod uwagę indywidualne potrzeby uczestnika, uwzględniając koszty utrzymania oraz zapewnienie niezbędnych warunków do nauki i rozwoju.</p>",
        },
        {
          question:
            "Jakie warunki muszę spełnić, żeby wziąć udział w programie Alkantara?",
          answer:
            "<p>Do udziału w Programie Alkantara zapraszamy wszystkich zdolnych maturzystów, którzy chcą studiować na Trójmiejskich uczelniach. Jeśli wyróżniasz się świetnymi wynikami w nauce i chcesz od pierwszego roku studiów budować swoje zawodowe kompetencje, zgłoś się do Programu Alkantara. By to zrobić, skontaktuj się ze swoim wychowawcą lub doradcą zawodowym w swojej szkole. To osoby, które pomogą Ci wypełnić wszystkie dokumenty potrzebne w procesie rekrutacji. Nabór na rok akademicki 2021/2022 trwa do 12 grudnia 2020.</p>",
        },
      ]}
    />
  </Layout>
)

export default IndexPage

import styled from "styled-components"
import React from "react"
import { rgba } from "polished"

import { LayoutWrapper } from "../LayoutWrapper/LayoutWrapper"
import { color } from "../../utils/colors"
import { fonts } from "../../utils/fonts"

const StyledHero = styled.section`
  height: 600px;
  overflow: hidden;
  position: relative;

  &::before {
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: ${rgba(color.navyBlue, 0.5)};
    mix-blend-mode: multiply;
  }
  @media screen and (max-width: 1000px) {
    height: 400px;
  }
`

const StyledHeroCopy = styled.h2`
  font-size: 25px;
  font-style: italic;
  font-weight: 300;
  line-height: calc(60 / 38);
  letter-spacing: 0;
  text-align: left;
  color: ${color.white};
  p + p {
    margin-top: 1em;
  }
  @media screen and (max-width: 1000px) {
    font-size: 22px;
  }
  @media screen and (max-width: 767px) {
    font-size: 18px;
  }
`

const StyledBackground = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  z-index: -1;
`

const StyledWrapper = styled.div`
  width: 50%;
  @media screen and (max-width: 1000px) {
    width: 75%;
  }
  @media screen and (max-width: 767px) {
    width: 100%;
  }
`

const StyledLayoutWrapper = styled(LayoutWrapper)`
  padding-top: 80px;
  position: relative;
  @media screen and (max-width: 1000px) {
    padding-top: 40px;
  }
`

const StyledHeroHeading = styled.h3`
  ${fonts.headingLarge};
  font-size: 30px;
  margin-bottom: 20px;
  color: ${color.white};
`

const HeroQuote = ({ heroText, heroHeading, heroimg }) => (
  <StyledHero>
    <StyledBackground src={heroimg} />
    <StyledLayoutWrapper>
      <StyledWrapper>
        <StyledHeroHeading>{heroHeading}</StyledHeroHeading>
        <StyledHeroCopy dangerouslySetInnerHTML={{ __html: heroText }} />
      </StyledWrapper>
    </StyledLayoutWrapper>
  </StyledHero>
)

export default HeroQuote

import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { fonts } from "../../utils/fonts"
import { color } from "../../utils/colors"

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  &:not(:last-child) {
    border-right: solid 1px ${color.separatorGrey};
  }
  padding-left: 40px;
  padding-right: 40px;
  @media screen and (max-width: 1100px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  @media screen and (max-width: 767px) {
    border-right: none !important;
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
`

const StyledImageWrapper = styled.figure`
  height: 250px;
  margin-bottom: 40px;
`

const StyledImage = styled.img`
  object-fit: cover;
  height: 100%;
  width: 100%;
`

const StyledHeading = styled.h4`
  ${fonts.headingMedium};
  font-size: 24px;
`

const StyledText = styled.p`
  font-family: Helvetica;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: calc(33 / 23);
  letter-spacing: 0px;
  text-align: left;
  margin-top: 20px;
  @media screen and (max-width: 1100px) {
    font-size: 20px;
    margin-top: 20px;
  }
`

const CarouselSlide = ({ image, heading, text, className }) => (
  <StyledWrapper className={className}>
    <StyledImageWrapper>
      <StyledImage src={image} alt="" />
    </StyledImageWrapper>
    <StyledHeading>{heading}</StyledHeading>
    <StyledText>{text}</StyledText>
  </StyledWrapper>
)

CarouselSlide.propTypes = {
  image: PropTypes.object,
  heading: PropTypes.string,
  text: PropTypes.string,
}

export default CarouselSlide
